import { RefObject, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse as qsParse, stringify } from 'qs';

import { Box } from '@mui/material';
import { LoadMemberRequest } from '@one/api-models/lib/Admin/Members/Request/LoadMemberRequest';
import { LoadCriteria } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/LoadCriteria';
import { LoadResponse } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/LoadResponse';
import { Program } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/Program';
import { LoadCriteria as PurchaseLoadCriteria } from '@one/api-models/lib/Admin/ProgramSales/Purchase/LoadCriteria';
import { LoadResponse as PurchaseLoadResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/LoadResponse';
import { SummaryLoadResponse } from '@one/api-models/lib/Membership/Account/SummaryLoadResponse';
import { Metadata } from '@one/api-models/lib/Metadata/Metadata';

import { ApiError } from 'apiAccess/api-client';
import { PaymentBackdropLoading } from 'common';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useSwitchActiveBrand } from 'components/hooks/useSwitchActiveBrand';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { CustomerSelector } from 'components/views/customers/components/CustomerSelector';
import { Customer } from 'models/customers/Customer';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import {
  PaymentOptions,
  resetSalesOrderDataSliceState,
  selectBillingDetails,
  selectIsCompleteWithNoPayment,
  selectIsLoadingOrderPayment,
  selectMetadata,
  selectMetadataKeys,
  selectPurchaseData,
  selectSelectedCustomer,
  selectSelectedPrograms,
  setBillingDetails,
  setIsCompleteWithNoPayment,
  setMetadata,
  setMetadataKeys,
  setPaymentProviderType,
  setPurchaseData,
  setSelectedCustomer,
  setSelectedPaymentOption,
} from 'slices/salesOrderDataSlice';
import { Button, Typography } from 'styled';

import { SalesOrderMetadata } from './components/SalesOrderMetadata';
import { BillingDetailsDialog } from './BillingDetailsDialog';
import { SalesOrderDelivery } from './SalesOrderDelivery';
import { SalesOrderMemo } from './SalesOrderMemo';
import { SalesOrderPayment } from './SalesOrderPayment';
import { SelectSalesOrderItems } from './SelectSalesOrderItems';

interface CreateSalesOrderProps {
  openBillingDetails: boolean;
  requestBillingDetailsValidation: boolean;
  closeBillingDetailsDialog: () => void;
  openBillingDetailsDialog: () => void;
  paymentFormRef: RefObject<HTMLFormElement>;
}

export const CreateSalesOrder = ({
  openBillingDetails,
  requestBillingDetailsValidation,
  closeBillingDetailsDialog,
  openBillingDetailsDialog,
  paymentFormRef,
}: CreateSalesOrderProps) => {
  const testIdPrefix = 'SalesOrder';
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const { switchActiveBrand } = useSwitchActiveBrand();
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const isCompleteWithNoPayment = useSelector(selectIsCompleteWithNoPayment);
  const isLoadingOrderPayment = useSelector(selectIsLoadingOrderPayment);
  const metadataKeys = useSelector(selectMetadataKeys);
  const purchaseData = useSelector(selectPurchaseData);
  const metadata = useSelector(selectMetadata);
  const billingDetails = useSelector(selectBillingDetails);

  const [programs, setPrograms] = useState<Program[]>([]);

  const queryParams = qsParse(location.search, { ignoreQueryPrefix: true });
  const memberKey: string = queryParams?.memberKey as string;

  useEffect(() => {
    if (memberKey) {
      switchActiveBrand(memberKey);
      memberQuery.mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetSalesOrderDataSliceState(true));
    if (selectedCustomer) {
      programsQuery.mutate();
    } else {
      setPrograms([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  useEffect(() => {
    if (selectedPrograms && selectedPrograms.length > 0) {
      purchaseLoadQuery.mutate();
      dispatch(setIsCompleteWithNoPayment(selectedPrograms.some((program: Program) => program.price.amount === 0)));
      dispatch(setSelectedPaymentOption(PaymentOptions.OneTime));
    } else {
      dispatch(setPurchaseData(undefined));
      dispatch(setPaymentProviderType(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrograms]);

  const memberQuery = useMutation<SummaryLoadResponse, ApiError>(
    async () =>
      await api.members.load({
        memberKey: memberKey,
        brandKey: activeBrand?.key || '',
        partnerKey: activePartner?.key || '',
        listHandling: { skip: 0, take: -1, ascending: true },
      } as LoadMemberRequest),
    {
      onSuccess: (data) => {
        if (data?.accountSummary?.profileSummary) {
          const customer: Customer = {
            firstName: data?.accountSummary?.profileSummary?.firstName,
            lastName: data?.accountSummary?.profileSummary?.lastName ?? '',
            email: data?.accountSummary?.profileSummary?.email,
            phoneNumber: data?.accountSummary?.profileSummary.mobilePhone,
            accountStatus: data?.accountSummary?.memberStatus,
            dateOfBirth: data?.accountSummary?.profileSummary?.dateOfBirth,
            memberKey: data?.accountSummary?.memberKey,
          };
          dispatch(setSelectedCustomer(customer));
          const selectedCountry = data?.accountSummary?.profileSummary.country;
          dispatch(
            setBillingDetails({
              firstName: data?.accountSummary?.profileSummary?.firstName,
              lastName: data?.accountSummary?.profileSummary?.lastName ?? '',
              email: data?.accountSummary?.profileSummary?.email,
              streetAddress: data?.accountSummary?.profileSummary.address ?? '',
              city: data?.accountSummary?.profileSummary.city ?? '',
              state: data?.accountSummary?.profileSummary.state ?? '',
              stateCode:
                selectedCountry === 'US' || selectedCountry === 'CA'
                  ? data?.accountSummary?.profileSummary.state
                  : undefined,
              zipCode: data?.accountSummary?.profileSummary.zipCode ?? '',
              country: data?.accountSummary?.profileSummary.country ?? '',
              aptNumber: '',
            }),
          );
        }
      },
      onError: (error) => addApiError(error),
    },
  );

  const programsQuery = useMutation<LoadResponse, ApiError>(
    async () =>
      await api.programSales.chooseProgramLoad({
        brandKey: activeBrand?.key,
        memberKey: selectedCustomer?.memberKey,
      } as LoadCriteria),
    {
      onSuccess: (data) => {
        setPrograms(data.programs);
      },
      onError: (error) => addApiError(error),
    },
  );

  const purchaseLoadQuery = useMutation<PurchaseLoadResponse, ApiError>(
    async () =>
      await api.programSales.purchaseDetailsLoad({
        brandKey: activeBrand?.key,
        partnerKey: activePartner?.key,
        memberKey: selectedCustomer?.memberKey,
        programId: selectedPrograms[0]?.id,
      } as PurchaseLoadCriteria),
    {
      onSuccess: (data) => {
        dispatch(setPurchaseData(data));
        dispatch(setBillingDetails(data.billingDetails));
        dispatch(setMetadataKeys(data.metadataKeys));
      },
      onError: (error) => addApiError(error),
    },
  );

  const handleSaveMetadata = (metadata: Metadata[]) => {
    dispatch(setMetadata(metadata));
  };

  const handleCustomerChange = (customer: Customer | null) => {
    dispatch(setSelectedCustomer(customer));
    if (!customer) {
      dispatch(setBillingDetails(undefined));
    } else {
      dispatch(
        setBillingDetails({
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email,
          streetAddress: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
          aptNumber: '',
        }),
      );

      navigate({
        pathname: '/sales/new',
        search: stringify({
          memberKey: customer.memberKey,
        }),
      });
    }
  };

  return (
    <Box>
      <LoadingScreen
        open={memberQuery.isLoading || programsQuery.isLoading || purchaseLoadQuery.isLoading}
        message="Loading..."
      />

      <Typography variant="h5">Customer</Typography>
      <Box sx={{ maxWidth: { xs: '100%', sm: '300px' }, mt: 2 }}>
        <CustomerSelector
          testId={testIdPrefix}
          disabled={false}
          handleChange={handleCustomerChange}
          defaultValue={selectedCustomer}
        />
      </Box>
      <SelectSalesOrderItems testId={testIdPrefix} programs={programs} />
      {!!purchaseData && !!selectedCustomer && !isCompleteWithNoPayment && (
        <SalesOrderPayment formRef={paymentFormRef} memberId={selectedCustomer.memberKey} />
      )}
      {!!purchaseData && !!selectedCustomer && (
        <>
          <Button
            sx={{ mt: 3, pl: 0 }}
            variant="text"
            data-testId={`${testIdPrefix}OpenBillingDetailsDialog`}
            onClick={openBillingDetailsDialog}
          >
            Change Billing Details
          </Button>
          <BillingDetailsDialog
            testId={testIdPrefix}
            open={openBillingDetails}
            validateOnOpen={requestBillingDetailsValidation}
            handleClose={closeBillingDetailsDialog}
            billingDetails={billingDetails}
            handleBillingDetailsChange={(data) => {
              dispatch(setBillingDetails(data));
            }}
          />
        </>
      )}
      {isLoadingOrderPayment && isCompleteWithNoPayment && (
        <PaymentBackdropLoading
          isLoading={isLoadingOrderPayment}
          // Only/always show submit payment message
          isSubmittingPayment={true}
        />
      )}
      {!!metadataKeys && metadataKeys?.length > 0 && (
        <SalesOrderMetadata metadata={metadata} metadataKeys={metadataKeys} handleSave={handleSaveMetadata} />
      )}
      <Box display="none">
        <SalesOrderDelivery />
        <SalesOrderMemo />
      </Box>
    </Box>
  );
};
