import { useMemo } from 'react';

import { StripeGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/StripeGatewayConfiguration';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

type Props = { paymentGatewayConfiguration: StripeGatewayConfiguration; children: React.ReactNode };

export const StripeWrapper = ({ paymentGatewayConfiguration, children }: Props) => {
  const stripePromise = useMemo(() => {
    if (!paymentGatewayConfiguration) {
      throw new Error('Missing Payment Configuration');
    }

    if (paymentGatewayConfiguration.accountId != null) {
      return loadStripe(paymentGatewayConfiguration.publishableKey, {
        stripeAccount: paymentGatewayConfiguration.accountId,
      });
    }

    return loadStripe(paymentGatewayConfiguration.publishableKey);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentGatewayConfiguration]);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};
