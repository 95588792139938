import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Shift4GatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/Shift4GatewayConfiguration';

import { useShift4ProgramSales } from 'components/hooks/useShift4ProgramSales';
import { selectActiveBrand } from 'slices/applicationDataSlice';
import {
  selectBillingDetails,
  selectIsCompleteWithNoPayment,
  selectIsLoadingOrderPayment,
  selectMetadata,
  selectPaymentError,
  setPaymentError,
} from 'slices/salesOrderDataSlice';

import { PaymentBackdropLoading } from './components/PaymentBackdropLoading';
import { PaymentSkeleton } from './components/PaymentSkeleton';
import { Shift4Form } from './components/Shift4Form';

interface PaymentDetailsShift4Props {
  paymentGatewayConfiguration: Shift4GatewayConfiguration;
  isLoadingConfiguration: boolean;
  refetchConfiguration: () => void;
}

export const PaymentDetailsShift4: FC<PaymentDetailsShift4Props> = ({
  paymentGatewayConfiguration,
  isLoadingConfiguration,
  refetchConfiguration,
}) => {
  const dispatch = useDispatch();
  const { performCompleteOrderWithPayment } = useShift4ProgramSales();
  const isLoading = useSelector(selectIsLoadingOrderPayment);
  const isCompleteWithNoPayment = useSelector(selectIsCompleteWithNoPayment);
  const paymentError = useSelector(selectPaymentError);
  const billingDetails = useSelector(selectBillingDetails);
  const activeBrand = useSelector(selectActiveBrand);
  const metadata = useSelector(selectMetadata);

  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setIsSubmittingPayment(false);
    }
  }, [isLoading]);

  const paymentSubmitHandler = async (shift4CardToken: string, paymentMethodExpiration: string) => {
    setIsSubmittingPayment(true);
    dispatch(setPaymentError(undefined));
    performCompleteOrderWithPayment(shift4CardToken, paymentMethodExpiration, billingDetails, undefined, metadata);
  };

  const updatePaymentData = () => {
    setIsSubmittingPayment(false);
    refetchConfiguration();
  };

  return (
    <>
      {!isLoadingConfiguration || isSubmittingPayment ? (
        <Shift4Form
          brand={activeBrand}
          paymentGatewayConfiguration={paymentGatewayConfiguration}
          updatePaymentData={updatePaymentData}
          submitPayment={paymentSubmitHandler}
          error={paymentError}
          testId="PaymentDetailsShift4Form"
        />
      ) : (
        <PaymentSkeleton />
      )}

      <PaymentBackdropLoading
        isCompleteWithNoPayment={isCompleteWithNoPayment}
        isLoading={isLoading}
        isLoadingConfiguration={isLoadingConfiguration}
        isSubmittingPayment={isSubmittingPayment}
      />
    </>
  );
};
