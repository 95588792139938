import { GridFilterModel } from '@mui/x-data-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

interface SalesOrderListData {
  allBrandsEnabled: boolean;
  filtersModel: GridFilterModel;
}

const initialState: SalesOrderListData = {
  allBrandsEnabled: false,
  filtersModel: { items: [] },
};

const salesOrderListDataSlice = createSlice({
  name: 'salesOrderListData',
  initialState,
  reducers: {
    setAllBrandsEnabled: (state, action: PayloadAction<boolean>) => {
      state.allBrandsEnabled = action.payload;
    },
    setFiltersModel: (state, action: PayloadAction<GridFilterModel>) => {
      state.filtersModel = action.payload;
    },
  },
});

export const { setAllBrandsEnabled, setFiltersModel } = salesOrderListDataSlice.actions;

export const selectAllBrandsEnabled = (state: RootState) => state.salesOrderListData.allBrandsEnabled;
export const selectFiltersModel = (state: RootState) => state.salesOrderListData.filtersModel;

export default salesOrderListDataSlice.reducer;
