import { useEffect, useState } from 'react';
import { endOfMonth, format } from 'date-fns';

import { Alert, Box, Button, Grid } from '@mui/material';
import { Shift4GatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/Shift4GatewayConfiguration';

import { SectionTitle } from 'components/_common/SectionTitle';

import { I4GO_IFRAME_STYLES, I4GO_SCRIPT_URL, JQUERY_URL, SCRIPT_TYPE } from './constants/Shift4Payment';

import 'paymentfont/css/paymentfont.min.css';

const isScriptLoaded = (scriptSrc: string) => {
  const scripts = document.querySelectorAll('script');
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === scriptSrc) {
      return true;
    }
  }
  return false;
};

export interface PaymentFormParams {
  paymentGatewayConfiguration: Shift4GatewayConfiguration;
  title?: string;
  brand: any;
  testId: string;
  updatePaymentData: () => void;
  submitPayment: (shift4CardToken: string, paymentMethodExpiration: string) => void;
  error?: string;
}

export const Shift4Form = ({
  paymentGatewayConfiguration,
  title,
  brand,
  testId,
  updatePaymentData,
  submitPayment,
  error,
}: PaymentFormParams) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [jQueryLoaded, setJQueryLoaded] = useState(false);
  const [iFrameLoaded, setIFrameLoaded] = useState(false);

  useEffect(() => {
    // Load jquery script
    if (isScriptLoaded(JQUERY_URL)) {
      setJQueryLoaded(true);
      return;
    }

    const jqueryScript = document.createElement('script');
    jqueryScript.src = JQUERY_URL;
    jqueryScript.addEventListener('load', () => setJQueryLoaded(true));
    document.body.appendChild(jqueryScript);

    return () => {
      if (jqueryScript) {
        jqueryScript.removeEventListener('load', () => setJQueryLoaded(true));
      }
    };
  }, []);

  useEffect(() => {
    if (!jQueryLoaded) return;

    if (isScriptLoaded(`${paymentGatewayConfiguration.iFrameUrl}${I4GO_SCRIPT_URL}`)) {
      setScriptLoaded(true);
      return;
    }

    // LOAD I4GO SCRIPT
    const i4GoScript = document.createElement('script');
    i4GoScript.src = `${paymentGatewayConfiguration.iFrameUrl}${I4GO_SCRIPT_URL}`;
    i4GoScript.type = SCRIPT_TYPE;
    i4GoScript.addEventListener('load', () => setScriptLoaded(true));
    document.body.appendChild(i4GoScript);

    return () => {
      if (i4GoScript) {
        i4GoScript.removeEventListener('load', () => setScriptLoaded(true));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jQueryLoaded, brand]);

  useEffect(() => {
    if (!scriptLoaded) return;

    const i4goInstance = $('#i4goFrame').i4goTrueToken({
      debug: false,
      remoteDebug: false,
      url: paymentGatewayConfiguration.iFrameUrl,
      server: paymentGatewayConfiguration.serverUrl,
      accessBlock: paymentGatewayConfiguration.accessBlock,
      self: document.location,
      template: 'bootstrap2',
      language: 'en',
      encryptedOnlySwipe: false,
      cssRules: [I4GO_IFRAME_STYLES],
      cardType: {
        visible: false,
      },
      cvv2Code: {
        placeholder: 'CVC',
      },
      submitButton: { label: 'CONFIRM AND PAY' },
      onSuccess: (form: any, data: any) => {
        // Use 01 as default day

        const paymentMethodExpiration = format(
          endOfMonth(new Date(`${data.i4go_expirationyear}-${data.i4go_expirationmonth}-01`)),
          'yyyy-MM-dd',
        );
        submitPayment(data.i4go_uniqueid, paymentMethodExpiration);
      },
      onFailure: async (form: any, data: any) => {
        // Session expired, rerender iframe
        if (data.i4go_responsecode === '503' || data.i4go_responsecode === '504') {
          updatePaymentData();
        }
      },
    });

    const iframe = document.getElementById(i4goInstance?.frameName) as HTMLIFrameElement;
    iframe?.addEventListener('load', () => setIFrameLoaded(true));

    return () => {
      iframe?.removeEventListener('load', () => setIFrameLoaded(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded]);

  return (
    <Box sx={{ pt: title ? 2 : 0, maxWidth: '450px' }}>
      {iFrameLoaded && title && <SectionTitle sx={{ mb: 4 }} title={title} />}
      {error && (
        <Alert
          severity="error"
          sx={{ mb: 2 }}
          action={
            <Button
              onClick={() => {
                updatePaymentData();
              }}
              data-testid={`${testId}TryAgainButton`}
            >
              Try Again
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Grid container display="flex" justifyContent="flex-start">
        <div
          id="i4goFrame"
          style={{
            width: '100%',
            height: iFrameLoaded ? 'auto' : 0,
          }}
        ></div>
      </Grid>
    </Box>
  );
};
