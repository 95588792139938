import { SetStateAction, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Grid, Link, Paper } from '@mui/material';
import { ExportCriteria } from '@one/api-models/lib/Admin/Reports/TransactionsReport/ExportCriteria';

import { ApiError, FileResponse } from 'apiAccess/api-client';
import { ActionButton } from 'components/_common/ActionButton';
import { PageHeader } from 'components/_common/PageHeader';
import UncontrolledDatePicker from 'components/_common/UncontrolledDatePicker';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';

export const TransactionReport = () => {
  const currentDay = new Date();

  const { api } = useApiHelpers();
  const { addApiError } = useToastMessage();

  const { dateIsValid, getStartDate, getEndDate, formatDate } = useFormat();
  const [startDate, setStartDate] = useState<Date | undefined>(currentDay);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const testIdPrefix = 'TransactionsReport';

  const linkRef = useRef<HTMLAnchorElement>(null);

  const getTransactionReportFileName = (startDate: Date | undefined, endDate: Date | undefined) => {
    if (startDate && endDate)
      return `Transactions_Report_${formatDate(startDate, true, 'yyyy-MM-dd')}_${formatDate(
        endDate,
        true,
        'yyyy-MM-dd',
      )}.csv`;
    if (startDate && !endDate) return `Transactions_Report_From_${formatDate(startDate, true, 'yyyy-MM-dd')}.csv`;
    if (!startDate && endDate) return `Transactions_Report_Until_${formatDate(endDate, true, 'yyyy-MM-dd')}.csv`;
    if (!startDate && !endDate) return `Transactions_Report.csv`;
  };

  const exportMutation = useMutation<FileResponse, ApiError, ExportCriteria, unknown>(
    async (request: ExportCriteria) => {
      return await api.reports.transactionReportExport(request);
    },
    {
      onSuccess: async (response: FileResponse) => {
        if (linkRef === undefined || linkRef === null || linkRef.current === null) return;

        const fileName = response.fileName ?? getTransactionReportFileName(startDate, endDate);

        linkRef.current.href = window.URL.createObjectURL(response.blob);
        linkRef.current.setAttribute('download', fileName);
        linkRef.current.click();
      },
      onError: addApiError,
    },
  );

  const click = (startDate: Date | undefined, endDate: Date | undefined) => {
    exportMutation.mutate({ brandKey: '', startDate: startDate, endDate: endDate });
  };

  return (
    <Box>
      <PageHeader title="Transactions Report" testId={testIdPrefix} />
      <Paper sx={{ mb: 2, p: 3 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Grid item xs={12} md="auto">
            <Grid container alignItems="center" sx={{ justifyContent: { xs: 'space-between', md: 'flex-start' } }}>
              <Grid item xs={5.3} md="auto">
                <UncontrolledDatePicker
                  label="Start Date"
                  placeholder="Start Date"
                  value={startDate}
                  onChange={(newValue) => {
                    if (
                      dateIsValid(newValue?.toLocaleDateString('en-US')) &&
                      startDate?.toLocaleDateString('en-US') !== newValue?.toLocaleDateString('en-US')
                    ) {
                      setStartDate(newValue as SetStateAction<Date | undefined>);
                      if (endDate && newValue && new Date(newValue).getTime() > new Date(endDate).getTime()) {
                        setEndDate(newValue);
                      }
                    }
                  }}
                  maxDate={currentDay}
                  testId={`${testIdPrefix}Start`}
                />
              </Grid>
              <Grid item sx={{ px: 1 }}>
                &#8213;
              </Grid>
              <Grid item xs={5.3} md="auto">
                <UncontrolledDatePicker
                  label="End Date"
                  placeholder="End Date"
                  value={endDate}
                  onChange={(newValue) => {
                    if (
                      dateIsValid(newValue?.toLocaleDateString('en-US')) &&
                      endDate?.toLocaleDateString('en-US') !== newValue?.toLocaleDateString('en-US')
                    ) {
                      setEndDate(newValue as SetStateAction<Date | undefined>);
                      if (startDate && newValue && new Date(newValue).getTime() < new Date(startDate).getTime()) {
                        setStartDate(newValue);
                      }
                    }
                  }}
                  maxDate={currentDay}
                  testId={`${testIdPrefix}End`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item justifyContent="center" alignItems="center" sx={{ mt: { xs: 2, md: 0 } }}>
            <ActionButton
              icon={<FileDownloadOutlinedIcon />}
              loading={exportMutation.isLoading}
              onClick={() => click(getStartDate(startDate), getEndDate(endDate))}
              testId={`${testIdPrefix}Export`}
            >
              Export
            </ActionButton>
            <Link ref={linkRef} style={{ display: 'none' }} data-testid={`${testIdPrefix}DownloadLink`}>
              download link
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
