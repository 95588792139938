import { Grid, Link, Typography } from '@mui/material';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';

import { Info } from 'components/views/customers/components/profile/Assets/OrderDetails/Info';

interface OrderSummaryProps {
  order: Order | undefined;
}
export const OrderSummary = ({ order }: OrderSummaryProps) => {
  const billingAddressItems = [
    order?.billingDetails?.billingAddress?.addressLine1,
    order?.billingDetails?.billingAddress?.city,
    order?.billingDetails?.billingAddress?.state || order?.billingDetails?.billingAddress?.stateCode,
    order?.billingDetails?.billingAddress?.postalCode,
    order?.billingDetails?.billingAddress?.country,
  ].filter((item) => !!item);

  const shippingAddressItems = [
    order?.orderItems[0]?.shippingInformation?.address?.addressLine1,
    order?.orderItems[0]?.shippingInformation?.address?.city,
    order?.orderItems[0]?.shippingInformation?.address?.state ||
      order?.orderItems[0]?.shippingInformation?.address?.stateCode,
    order?.orderItems[0]?.shippingInformation?.address?.postalCode,
    order?.orderItems[0]?.shippingInformation?.address?.country,
  ].filter((item) => !!item);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Info
          label="Billed to"
          labelStyle={{ fontWeight: 600 }}
          value={
            <>
              <Typography variant="body1">
                {`${order?.billingDetails?.firstName || ''} ${order?.billingDetails?.lastName || ''}`}
              </Typography>
              <Link href={`mailto:${order?.billingDetails?.email}`} sx={{ wordBreak: 'break-word' }} target="_blank">
                {order?.billingDetails?.email}
              </Link>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Info
          label="Billing Details"
          labelStyle={{ fontWeight: 600 }}
          value={
            <>
              <Typography variant="body1">
                {billingAddressItems.length > 0 ? billingAddressItems.join(', ') : 'No address'}
              </Typography>
              <Typography variant="body1">{order?.billingDetails?.phone || ''}</Typography>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Info
          label="Shipping Details"
          labelStyle={{ fontWeight: 600 }}
          value={
            <>
              <Typography variant="body1">
                {shippingAddressItems.length > 0 ? shippingAddressItems.join(', ') : 'No address'}
              </Typography>
              <Typography variant="body1">{order?.orderItems[0]?.shippingInformation?.contactEmail ?? ''}</Typography>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Info label="Sold By" labelStyle={{ fontWeight: 600 }} value={order?.soldByEmail ?? '-'} />
      </Grid>
    </Grid>
  );
};
