export enum OBODestinations {
  Merchandise = 'shopping',
  Wine = 'wine',
  Hotel = 'hotels',
  Resort = 'resorts',
  Week = 'weeks',
  FeaturedResort = 'featured-resorts',
  Villa = 'villas',
  Car = 'cars',
  Cruise = 'cruises',
  Experience = 'experiences',
  Air = 'flights',
  'Cruise.Ocean' = 'cruises',
  'Cruise.River' = 'river-cruises',
}
