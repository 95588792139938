import { useState } from 'react';
import { useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { StripeGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/StripeGatewayConfiguration';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';

import { useStripeCreatePayment } from 'components/hooks/useStripeCreatePayment';
import { selectCreatePaymentMethodError, selectIsLoadingCreatePaymentMethod } from 'slices/paymentDataSlice';
import { validateStripeCard } from 'utils/stripe';

import { InternalValidationErrors, StripeForm } from '../../views/paymentPlans/components/StripeForm';

export interface AddPaymentMethodStripeComponentProps {
  testId: string;
  memberId: string;
  paymentGatewayConfiguration: StripeGatewayConfiguration;
  onClose: () => void;
  refetchPaymentMethodList: () => void;
  resetCreatePaymentMethodError: () => void;
  setAddPaymentModalOpen: (value: boolean) => void;
  billingDetails: BillingDetails;
}

export const AddPaymentMethodStripeComponent = (props: AddPaymentMethodStripeComponentProps) => {
  const {
    testId,
    memberId,
    onClose,
    paymentGatewayConfiguration,
    refetchPaymentMethodList,
    setAddPaymentModalOpen,
    resetCreatePaymentMethodError,
    billingDetails,
  } = props;
  const stripe = useStripe();
  const { performCreatePaymentMethod } = useStripeCreatePayment();

  const createPaymentMethodError = useSelector(selectCreatePaymentMethodError);
  const isLoadingCreatePaymentMethod = useSelector(selectIsLoadingCreatePaymentMethod);

  const [cardErrors, setCardErrors] = useState<InternalValidationErrors>({
    cardNumberError: undefined,
    cardExpiryError: undefined,
    cardCvcError: undefined,
  });

  const elements = useElements();

  const createPaymentMethodFormSubmitHandler = async () => {
    resetCreatePaymentMethodError();
    if (elements) {
      const { cardElement, creditCardErrors } = validateStripeCard(elements);
      setCardErrors(creditCardErrors);

      if (creditCardErrors.cardNumberError || creditCardErrors.cardExpiryError || creditCardErrors.cardCvcError) return;

      handleSubmitAddPaymentMethod(cardElement, false, memberId);
    }
  };

  const handleSubmitAddPaymentMethod = async (
    card: StripeCardNumberElement,
    isDefault: boolean,
    memberKey: string | undefined,
  ) => {
    await performCreatePaymentMethod(
      stripe,
      card,
      isDefault,
      paymentGatewayConfiguration.gatewayIdentifier,
      memberKey,
      billingDetails,
      refetchPaymentMethodList,
      setAddPaymentModalOpen,
    );
  };

  const closeModal = () => {
    resetCreatePaymentMethodError();
    onClose();
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StripeForm
              errors={cardErrors}
              error={createPaymentMethodError}
              resetError={resetCreatePaymentMethodError}
              testId={testId}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          disableRipple
          onClick={closeModal}
          disabled={isLoadingCreatePaymentMethod}
          data-testid={`${testId}CancelButton`}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={isLoadingCreatePaymentMethod}
          disableRipple
          variant="contained"
          type="submit"
          onClick={createPaymentMethodFormSubmitHandler}
          size="small"
          loading={isLoadingCreatePaymentMethod}
          data-testid={`${testId}SaveButton`}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </>
  );
};
