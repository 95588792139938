import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import {
  orderFulfillmentStatusOptions,
  orderPaymentStatusOptions,
  orderStatusOptions,
} from 'components/_common/constants/StatusOptions';
import { ActionMenu } from 'components/_common/DataGrid/ActionMenu';
import { amountFilterOperators } from 'components/_common/DataGrid/FilterAmountInput';
import { dateRangeFilterOperators } from 'components/_common/DataGrid/FilterDateInput';
import { nameFilterOperators } from 'components/_common/DataGrid/FilterNameInput';
import { StatusChip } from 'components/_common/StatusChip';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { Tooltip } from 'styled/Tooltip';

interface OrderColumnsProps {
  handleOrderDetailsClick?: (params: any) => void;
  handleViewCustomerClick?: (params: any) => void;
  hideFulfillmentStatusFilter?: boolean;
}

const OrderColumns = ({ handleViewCustomerClick, hideFulfillmentStatusFilter }: OrderColumnsProps) => {
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const { showMessage } = useToastMessage();

  const columns: GridColDef[] = [
    {
      field: 'partner',
      headerName: 'Partner',
      type: 'string',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width: 80,
      valueGetter: (params: any) => params?.row?.customer?.partnerKey,
      renderCell: (params: any) => (
        <Typography sx={{ fontWeight: 600 }}>{params?.row?.customer?.partnerKey}</Typography>
      ),
    },
    {
      field: 'brand',
      headerName: 'Brand',
      type: 'string',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width: 65,
      valueGetter: (params: any) => params?.row?.customer?.brandKey,
      renderCell: (params: any) => <Typography sx={{ fontWeight: 600 }}>{params?.row?.customer?.brandKey}</Typography>,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      filterOperators: amountFilterOperators,
      align: 'right',
      headerAlign: 'right',
      minWidth: 110,
      valueGetter: (params: any) => params?.row?.orderTotalPrice?.amount,
      renderCell: (params: any) => (
        <Typography sx={{ fontWeight: 600, color: 'black' }}>
          {formatCurrency(params?.row?.orderTotalPrice?.amount, params?.row?.orderTotalPrice?.currency)}
        </Typography>
      ),
    },
    {
      field: 'paid',
      headerName: 'Paid',
      type: 'number',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      align: 'right',
      headerAlign: 'right',
      minWidth: 110,
      valueGetter: (params: any) => params?.row?.amountPaid?.amount,
      renderCell: (params: any) => (
        <Typography sx={{ color: 'black' }}>
          {formatCurrency(params?.row?.amountPaid?.amount, params?.row?.amountPaid?.currency)}
        </Typography>
      ),
    },
    {
      field: 'balance',
      headerName: 'Balance',
      type: 'number',
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      filterOperators: amountFilterOperators,
      align: 'right',
      headerAlign: 'right',
      minWidth: 80,
      maxWidth: 110,
      valueGetter: (params: any) => params?.row?.balance?.amount,
      renderCell: (params: any) => (
        <Typography sx={{ color: 'black' }}>
          {formatCurrency(params?.row?.balance?.amount, params?.row?.balance?.currency)}
        </Typography>
      ),
    },
    {
      field: 'Currency',
      headerName: '',
      type: 'string',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width: 40,
      valueGetter: (params: any) => params?.row?.orderTotalPrice?.currency,
      renderCell: (params: any) => <>{params?.row?.orderTotalPrice?.currency}</>,
    },
    {
      field: 'orderStatus',
      headerName: 'Status',
      type: 'singleSelect',
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      valueOptions: Object.entries(orderStatusOptions).map(([key, { name }]) => ({
        value: key,
        label: name,
      })),
      width: 126,
      valueGetter: (params: any) => params?.row?.orderStatus,
      renderCell: (params: any) =>
        params.row.orderStatus != null ? (
          <Tooltip
            arrow
            title={orderStatusOptions[params.row.orderStatus]?.name ?? ''}
            fontSize="0.0775"
            maxWidth="900px"
          >
            <Box width="100%">
              <StatusChip status={orderStatusOptions[params.row.orderStatus] || params.row.orderStatus} />
            </Box>
          </Tooltip>
        ) : (
          <StatusChip status={'-'} />
        ),
    },
    {
      field: 'orderPaymentStatus',
      headerName: 'Payment',
      type: 'singleSelect',
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      valueOptions: Object.entries(orderPaymentStatusOptions).map(([key, { name }]) => ({
        value: key,
        label: name,
      })),
      width: 129,
      valueGetter: (params: any) => params?.row?.orderPaymentStatus,
      renderCell: (params: any) =>
        params.row.orderPaymentStatus != null ? (
          <Tooltip
            arrow
            title={orderPaymentStatusOptions[params.row.orderPaymentStatus]?.name ?? ''}
            fontSize="0.0775"
            maxWidth="900px"
          >
            <Box width="100%">
              <StatusChip
                status={orderPaymentStatusOptions[params.row.orderPaymentStatus] || params.row.orderPaymentStatus}
              />
            </Box>
          </Tooltip>
        ) : (
          <StatusChip status={'-'} />
        ),
    },
    {
      field: 'orderNumber',
      headerName: 'Order',
      type: 'string',
      disableColumnMenu: true,
      filterable: true,
      sortable: false,
      width: 230,
      valueGetter: (params: any) => params?.row?.orderNumber,
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params?.row?.orderNumber}
        </Typography>
      ),
    },
    {
      field: 'customerEmail',
      headerName: 'Customer',
      type: 'string',
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      width: 350,
      valueGetter: (params: any) => params?.row?.customer?.email,
      renderCell: (params: any) => (
        <Tooltip
          arrow
          title={`${params?.row?.customer?.firstName} ${params?.row?.customer?.lastName}`}
          fontSize="0.0775"
          maxWidth="900px"
        >
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {params?.row?.customer?.email}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'orderItems',
      headerName: 'Items',
      type: 'string',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width: 130,
      valueGetter: (params: any) => {
        return (
          params.row?.orderItems
            ?.map(
              (item: any) =>
                `${item?.type}: ${item?.name} ${
                  item?.supplierOrderReference ? ` (Ref: ${item.supplierOrderReference})` : ''
                }`,
            )
            .join(', ') || ''
        );
      },
      renderCell: (params: any) => {
        const itemTypes: Array<string> = params.row?.orderItems?.map((item: any) => item.type) || [];

        const itemShortDetails = itemTypes.length > 1 ? `${itemTypes[0]} + ${itemTypes?.length - 1}` : itemTypes[0];

        const itemDetails = params.row?.orderItems?.map((item: any, index: number) => (
          <Box key={index}>
            <Box sx={{ textWrap: 'nowrap', pt: itemTypes.length > 1 ? 0.3 : 0 }}>Item: {item?.name}</Box>
            {item?.supplierOrderReference && (
              <Box sx={{ textWrap: 'nowrap' }}>Supplier Reference: {item.supplierOrderReference}</Box>
            )}
          </Box>
        ));

        return (
          <Tooltip arrow title={itemTypes.length === 0 ? '' : itemDetails} fontSize="0.0775" maxWidth="900px">
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {itemShortDetails ? itemShortDetails : '—'}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'orderFulfillmentStatus',
      headerName: 'Fulfillment',
      type: 'singleSelect',
      disableColumnMenu: true,
      sortable: false,
      filterable: hideFulfillmentStatusFilter ? false : true,
      valueOptions: Object.entries(orderFulfillmentStatusOptions).map(([key, { name }]) => ({
        value: key,
        label: name,
      })),
      width: 126,
      valueGetter: (params: any) => params?.row?.orderFulfillmentStatus,
      renderCell: (params: any) =>
        params.row.orderFulfillmentStatus != null ? (
          <Tooltip
            arrow
            title={orderFulfillmentStatusOptions[params.row.orderFulfillmentStatus]?.name ?? ''}
            fontSize="0.0775"
            maxWidth="900px"
          >
            <Box width="100%">
              <StatusChip
                status={
                  orderFulfillmentStatusOptions[params.row.orderFulfillmentStatus] || params.row.orderFulfillmentStatus
                }
              />
            </Box>
          </Tooltip>
        ) : (
          <StatusChip status={'-'} />
        ),
    },
    {
      field: 'createdDateTime',
      headerName: 'Date',
      type: 'date',
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      filterOperators: dateRangeFilterOperators,
      width: 150,
      valueGetter: (params: any) => new Date(params?.row?.createdDate),
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {formatDate(params?.row?.createdDate, false, 'dd MMM, HH:mm')}
        </Typography>
      ),
    },
    {
      field: 'customerKey',
      headerName: 'Customer Key',
      type: 'string',
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      filterable: true,
      valueGetter: (params: any) => params?.row?.customer?.memberKey,
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {params?.row?.customer?.memberKey}
        </Typography>
      ),
    },
    {
      field: 'supplierOrderReference',
      headerName: 'Supplier Order Ref',
      type: 'string',
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      filterable: true,
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      type: 'string',
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      filterOperators: nameFilterOperators,
      valueGetter: (params: any) => `${params?.row?.customer?.firstName} ${params?.row?.customer?.lastName}`,
    },
    {
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ActionMenu
          onViewCustomerClick={() => handleViewCustomerClick && handleViewCustomerClick(params)}
          onCopyIdClick={() =>
            navigator.clipboard
              .writeText(params?.row?.orderNumber)
              .then(() => {
                showMessage('The order number has been copied to the clipboard.', 'success');
              })
              .catch(() => {
                showMessage('The order number could not be copied.', 'error');
              })
          }
          tableType="salesOrders"
        />
      ),
      align: 'center',
      width: 20,
    },
  ];
  return columns;
};
export default OrderColumns;
