import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { CreateRequest } from '@one/api-models/lib/Campaign/Promotion/Request/CreateRequest';
import { CreateResponse } from '@one/api-models/lib/Campaign/Promotion/Response/CreateResponse';
import { RetrieveResponse } from '@one/api-models/lib/Campaign/Promotion/Response/RetrieveResponse';
import { FiatCurrencyDefinition } from '@one/api-models/lib/FiatCurrencyDefinition';

import { ApiError } from 'apiAccess/api-client';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { PromotionSummaryForm } from './promotionTabs/PromotionSummaryForm';

export const AddPromotionView = () => {
  const navigate = useNavigate();
  const { api } = useApiHelpers();
  const { apiErrorHandler, showMessage } = useToastMessage();
  const isLoading = useIsMutating({ mutationKey: 'createPromotionMutation' }) > 0;
  const isLoadingDefaultData = useIsMutating({ mutationKey: 'retrievePromotionDefaultData' }) > 0;
  const [availableLanguageCodes, setAvailableLanguageCodes] = useState<string[]>([]);
  const [availableCurrencies, setAvailableCurrencies] = useState<FiatCurrencyDefinition[]>([]);
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key || '';
  const testIdPrefix = 'AddPromotion';

  useEffect(() => {
    retrievePromotionDefaultData.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBrand]);

  const retrievePromotionDefaultData = useMutation<RetrieveResponse, ApiError>(
    () =>
      api.promotions.retrieve({
        id: 0,
        brandKey,
      }),
    {
      mutationKey: 'retrievePromotionDefaultData',
      onSuccess: (value: RetrieveResponse) => {
        setAvailableCurrencies(value.availableCurrencies);
        setAvailableLanguageCodes(value.availableLanguageCodes);
      },
      onError: apiErrorHandler,
    },
  );

  const handleSavePromotion = async (data: Promotion) => {
    await createPromotionMutation.mutateAsync({
      promotion: data,
      brandKey: activeBrand?.key ?? '',
    });
  };

  const createPromotionMutation = useMutation<CreateResponse, ApiError, CreateRequest, unknown>(
    (request) => api.promotions.create(request),
    {
      mutationKey: 'createPromotionMutation',
      onSuccess: (value: CreateResponse) => {
        showMessage('Promotion added successfully.', 'success');
        navigate(`/marketing/promotions/${value.id}`);
      },
      onError: apiErrorHandler,
    },
  );

  return (
    <Box>
      <PageHeader title="New Promotion" testId={testIdPrefix} />
      <LoadingScreen open={isLoading || isLoadingDefaultData} message={'Please wait...'} />
      <Paper sx={{ p: 3 }}>
        <PromotionSummaryForm
          availableCurrencies={availableCurrencies}
          availableLanguageCodes={availableLanguageCodes}
          handleSavePromotion={handleSavePromotion}
          testId={testIdPrefix}
        />
      </Paper>
    </Box>
  );
};
