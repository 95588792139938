import { useDispatch } from 'react-redux';

import { GridFilterModel } from '@mui/x-data-grid';

import { OrderDataGrid } from 'components/views/sales/components/OrderDataGrid';
import {
  selectAllBrandsEnabled,
  selectFiltersModel,
  setAllBrandsEnabled,
  setFiltersModel,
} from 'slices/salesOrderListDataSlice';

export const Orders = () => {
  const dispatch = useDispatch();

  return (
    <OrderDataGrid
      title="Sales Orders"
      testId="SalesOrders"
      label="Show orders for all brands"
      selectAllBrandsEnabled={selectAllBrandsEnabled}
      selectFiltersModel={selectFiltersModel}
      setAllBrandsEnabled={(enableAllBrands: boolean) => dispatch(setAllBrandsEnabled(enableAllBrands))}
      setFiltersModel={(filters: GridFilterModel) => dispatch(setFiltersModel(filters))}
    />
  );
};
