import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import ExportButtonIcon from '@mui/icons-material/CallMade';
import FilterIcon from '@mui/icons-material/FilterAlt';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { DataGrid, GridFilterModel, GridOverlay, GridPaginationModel, useGridApiRef } from '@mui/x-data-grid';
import { AmountRange } from '@one/api-models/lib/Admin/Common/Filter/AmountRange';
import { DateRange } from '@one/api-models/lib/Admin/Common/Filter/DateRange';
import { OrderListResponse } from '@one/api-models/lib/Admin/Sales/OrderListResponse';

import { ApiError } from 'apiAccess/api-client';
import { RootState } from 'app/store';
import { Checkbox } from 'components/_common/DataGrid/Checkbox';
import { dataGridStyle } from 'components/_common/DataGrid/dataGridStyling';
import { Pagination } from 'components/_common/DataGrid/Pagination';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import OrderColumns from 'components/views/sales/components/OrderColumns';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import { Button } from 'styled';

export interface Filters {
  amountPaid?: AmountRange;
  customerFirstName?: string;
  customerLastName?: string;
  customerEmail?: string;
  createdDate?: DateRange;
  customerKey?: string;
  balance?: AmountRange;
  orderNumber?: string;
  orderStatuses: number[];
  orderFulfillmentStatuses: number[] | string[];
  orderPaymentStatuses: number[];
  supplierOrderReference?: string;
}

const defaultPageSize = 25;

interface OrderDataGridProps {
  selectAllBrandsEnabled: (state: RootState) => boolean;
  selectFiltersModel: (state: RootState) => GridFilterModel;
  setAllBrandsEnabled: (enabled: boolean) => void;
  setFiltersModel: (model: GridFilterModel) => void;
  hideFulfillmentStatusFilter?: boolean;
  defaultOrderFilters?: Filters;
  title: string;
  testId: string;
  label: string;
}

export const OrderDataGrid = ({
  selectAllBrandsEnabled,
  selectFiltersModel,
  setAllBrandsEnabled,
  setFiltersModel,
  hideFulfillmentStatusFilter,
  defaultOrderFilters = { orderStatuses: [], orderFulfillmentStatuses: [], orderPaymentStatuses: [] },
  title,
  testId,
  label,
}: OrderDataGridProps) => {
  const dataGridClasses = dataGridStyle();

  const apiRef = useGridApiRef();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { apiErrorHandler } = useToastMessage();
  const { api } = useApiHelpers();

  const navigate = useNavigate();

  const { getEndDate, getStartDate } = useFormat();

  const allBrandsEnabled = useSelector(selectAllBrandsEnabled);

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const filtersModel = useSelector(selectFiltersModel);

  const [shouldResetPage, setShouldResetPage] = useState(false);
  const [isInitialOrPartnerLoading, setIsInitialOrPartnerLoading] = useState(true);

  const [orders, setOrders] = useState<OrderListResponse | null>(null);
  const isLoading = useIsMutating({ mutationKey: 'orderListMutation' }) > 0;

  const orderMutation = useMutation<OrderListResponse, ApiError>(
    () =>
      api.order.loadOrderList({
        ...getFilterPayload(),
        listingCriteria: {
          skip: page * pageSize,
          take: pageSize,
        },
      }),
    {
      mutationKey: 'orderListMutation',
      onSuccess: (data: OrderListResponse) => {
        setOrders(data);
        setIsInitialOrPartnerLoading(false);
        setShouldResetPage(false);
      },
      onError: apiErrorHandler,
    },
  );

  useEffect(() => {
    orderMutation.mutateAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, filtersModel, allBrandsEnabled, activeBrand, activePartner]);

  useEffect(() => {
    setIsInitialOrPartnerLoading(true);
  }, [activePartner, activeBrand]);

  const handlePaginationChanges = (data: GridPaginationModel) => {
    setPageSize(data.pageSize);
    setPage(data.page);
  };

  const handleViewCustomerClick = (params: any) => {
    navigate(`/customers/${params.row.customer.memberKey}`, { state: { refetch: true } });
  };

  const resetPage = () => {
    setPage(0);
    setPageSize(defaultPageSize);
    setShouldResetPage(true);
  };

  const columns = OrderColumns({ handleViewCustomerClick, hideFulfillmentStatusFilter });

  const handleOrderDetailsClick = (params: any) => {
    const orderNumber = params?.row.orderNumber;
    navigate(`/sales/order/${orderNumber}`, {
      state: { orderNumber },
    });
  };

  const handleFilterModelChange = (filters: GridFilterModel) => {
    //Prevent API call when a new filter is added and the value is undefined and the amount is empty or the first name and last name are empty
    if (
      filters?.items?.every(
        (item) =>
          item?.value !== undefined &&
          item?.value.amount !== '' &&
          item?.value.firstName !== '' &&
          item?.value.lastName !== '',
      )
    ) {
      if (
        !!filtersModel.items[0] &&
        filtersModel.items[0].value &&
        filters.items[0].field !== filtersModel.items[0]?.field
      ) {
        filters.items[0].value = undefined;
      }
      setFiltersModel(filters);
      if (page !== 0) {
        resetPage();
      }
    }
    //Make API call when a filter is removed and the state between the filtersModel and the filters is different
    if (
      filters?.items?.every((item) => item?.value === undefined) &&
      filtersModel.items.every((item) => item?.value !== undefined) &&
      filtersModel.items.length > 0
    ) {
      if (
        !!filtersModel.items[0] &&
        filtersModel.items[0].value &&
        filters.items[0].field !== filtersModel.items[0]?.field
      ) {
        filters.items[0].value = undefined;
      }
      setFiltersModel(filters);
      resetPage();
    }
  };

  const getFilters = () => {
    let filters: Filters = { ...defaultOrderFilters };

    filtersModel.items.forEach((filterItem) => {
      const { field: filterType, value: filterValue } = filterItem;
      if (filterValue !== undefined) {
        switch (filterType) {
          case 'orderStatus':
            filters.orderStatuses = filterValue !== undefined ? [filterValue] : [];
            break;
          case 'orderFulfillmentStatus':
            filters.orderFulfillmentStatuses = filterValue !== undefined ? [filterValue] : [];
            break;
          case 'orderPaymentStatus':
            filters.orderPaymentStatuses = filterValue !== undefined ? [filterValue] : [];
            break;
          case 'orderNumber':
            filters.orderNumber = filterValue as string;
            break;
          case 'customerEmail':
            filters.customerEmail = filterValue as string;
            break;
          case 'customerKey':
            filters.customerKey = filterValue as string;
            break;
          case 'supplierOrderReference':
            filters.supplierOrderReference = filterValue as string;
            break;
          case 'createdDateTime':
            filters.createdDate = {
              startDate: getStartDate(new Date(filterValue.start)),
              endDate: getEndDate(new Date(filterValue.end)),
            };
            break;
          case 'customerName':
            if (
              filterValue.firstName &&
              filterValue.lastName &&
              (filterValue.firstName.length > 0 || filterValue.lastName.length > 0)
            ) {
              filters.customerFirstName = filterValue.firstName as string;
              filters.customerLastName = filterValue.lastName as string;
            }
            break;

          case 'amount':
            if (filterValue.comparison === 'greaterThan' && filterValue.amount) {
              filters.amountPaid = {
                greaterThan: {
                  amount: parseFloat(filterValue.amount),
                  currency: 'USD',
                  isEstimated: false,
                },
              };
            } else if (filterValue.amount) {
              filters.amountPaid = {
                lessThan: {
                  amount: parseFloat(filterValue.amount),
                  currency: 'USD',
                  isEstimated: false,
                },
              };
            }
            break;
          case 'balance':
            if (filterValue.comparison === 'greaterThan' && filterValue.amount) {
              filters.balance = {
                greaterThan: {
                  amount: parseFloat(filterValue.amount),
                  currency: 'USD', //Default value, the BE ignores it
                  isEstimated: false,
                },
              };
            } else if (filterValue.amount) {
              filters.balance = {
                lessThan: {
                  amount: parseFloat(filterValue.amount),
                  currency: 'USD', //Default value, the BE ignores it
                  isEstimated: false,
                },
              };
            }
            break;
          default:
            filters = { ...defaultOrderFilters };
        }
      }
    });
    return filters;
  };

  const getFilterPayload = () => {
    const filters = getFilters();

    const payload: any = {
      partnerKeys: allBrandsEnabled ? [] : [`${activePartner?.key}`],
      brandKeys: allBrandsEnabled ? [] : [`${activeBrand?.key}`],
      orderStatuses: filters.orderStatuses || [],
      orderFulfillmentStatuses: filters.orderFulfillmentStatuses || [],
      orderPaymentStatuses: filters.orderPaymentStatuses || [],
    };
    if (filters.orderNumber) {
      payload.orderNumber = filters.orderNumber;
    }
    if (filters.customerEmail) {
      payload.customerEmail = filters.customerEmail;
    }
    if (filters.customerKey) {
      payload.customerKey = filters.customerKey;
    }
    if (filters.supplierOrderReference) {
      payload.supplierOrderReference = filters.supplierOrderReference;
    }
    if (filters.createdDate) {
      payload.createdDate = {
        startDate: filters.createdDate.startDate,
        endDate: filters.createdDate.endDate,
      };
    }
    if (
      filters.customerFirstName &&
      filters.customerLastName &&
      (filters.customerFirstName.length > 0 || filters.customerLastName.length > 0)
    ) {
      payload.customerFirstName = filters.customerFirstName;
      payload.customerLastName = filters.customerLastName;
    }
    if (filters.amountPaid?.greaterThan?.amount || filters.amountPaid?.lessThan?.amount) {
      payload.amountPaid = filters.amountPaid as AmountRange;
    }
    if (filters.balance?.greaterThan?.amount || filters.balance?.lessThan?.amount) {
      payload.balance = filters.balance as AmountRange;
    }

    return payload;
  };
  return (
    <Box>
      <LoadingScreen open={isInitialOrPartnerLoading} message={'Loading...'} />
      <PageHeader
        title={title}
        testId={testId}
        titleAside={
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              mt: { xs: 3, sm: 0 },
              flexWrap: { xs: 'wrap', sm: 'nowrap' },
            }}
          >
            <Button
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorEl(event.currentTarget);
                apiRef.current.showFilterPanel();
              }}
            >
              <FilterIcon fontSize="small" />
              Filter
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                apiRef.current.exportDataAsCsv({
                  fileName: `Orders_${new Date().toISOString()}`,
                  allColumns: true,
                })
              }
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ExportButtonIcon fontSize="small" />
                Export
              </Box>
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/sales/new')}
              data-testid={`CreateSaleOrderButton`}
            >
              Create Sale Order
            </Button>
          </Box>
        }
      />
      <Box>
        <DataGrid
          apiRef={apiRef}
          paginationMode="server"
          filterMode="server"
          onPaginationModelChange={handlePaginationChanges}
          onFilterModelChange={handleFilterModelChange}
          onRowClick={(params) => handleOrderDetailsClick(params)}
          rows={orders?.orders?.items || []}
          loading={isLoading || orders == null}
          rowHeight={37}
          columns={columns}
          autoHeight
          getRowId={(row) => row.orderNumber}
          rowCount={orders?.orders.itemCount ?? 0}
          columnVisibilityModel={{
            partner: allBrandsEnabled,
            brand: allBrandsEnabled,
            customerKey: false,
            supplierOrderReference: false,
            customerName: false,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: page,
                pageSize: pageSize,
              },
            },
            filter: {
              filterModel: filtersModel,
            },
          }}
          components={{
            LoadingOverlay: () => <GridOverlay sx={{ backgroundColor: 'white', opacity: 0.5 }} />,
            Pagination: () => (
              <Pagination disablePaginationButtons={orders?.orders?.items.length === 0} resetPage={shouldResetPage} />
            ),
            BaseCheckbox: Checkbox,
            Toolbar: () => (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={allBrandsEnabled}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setAllBrandsEnabled(event.target.checked);
                        }}
                        inputProps={{
                          // eslint-disable-next-line
                          //@ts-ignore
                          'data-testid': `OrdersAllBrandsSwitchInput`,
                        }}
                      />
                    }
                    label={label}
                  />
                </Box>
              </Box>
            ),
          }}
          componentsProps={{
            filterPanel: {
              sx: {
                width: '430px',
              },
              filterFormProps: {
                operatorInputProps: {
                  disabled: true,
                  sx: { display: 'none' },
                },
                valueInputProps: {
                  sx: {
                    width: '220px',
                  },
                },
              },
            },
            panel: {
              anchorEl: anchorEl,
              placement: 'bottom',
            },
          }}
          sx={{
            '& .MuiDataGrid-row': {
              '&:hover': {
                '& .menuOptionButton': {
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#FFFFFF',
                  borderRadius: 2,
                },
              },
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              textTransform: 'uppercase',
            },
          }}
          className={dataGridClasses.root}
        />
      </Box>
    </Box>
  );
};
