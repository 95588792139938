import { RefObject, useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Skeleton } from '@mui/material';
import { BillingDetails } from '@one/api-models/lib/Admin/ProgramSales/Purchase/BillingDetails';

import { AddressAutocomplete, SelectedAddress } from 'common/AddressAutocomplete';
import ControlledAutocomplete from 'common/ControlledAutocomplete';
import ControlledTextField from 'common/ControlledTextField';
import { Brand } from 'models/Brand';
import { selectActiveBrand } from 'slices/applicationDataSlice';
import { selectCAStatesList, selectCountryList, selectUSStatesList } from 'slices/customersDataSlice';

interface BillingFormProps {
  validateOnOpen?: boolean;
  formRef: RefObject<HTMLFormElement>;
  initialDetails?: BillingDetails;
  testId: string;
  onBillingDataChanged: (confirmation: BillingDetails) => void;
}

export type BillingDetailsForm = {
  firstName?: string;
  lastName?: string;
  email?: string;
  streetAddress?: string;
  aptNumber?: string;
  city?: string;
  country?: string;
  state?: string;
  zipCode?: string;
};

export const BillingForm = ({
  formRef,
  validateOnOpen,
  initialDetails,
  testId,
  onBillingDataChanged,
}: BillingFormProps) => {
  const countryList = useSelector(selectCountryList);
  const usStatesList = useSelector(selectUSStatesList);
  const caStatesList = useSelector(selectCAStatesList);
  const activeBrand = useSelector(selectActiveBrand);

  const validationSchema: yup.SchemaOf<BillingDetailsForm> = yup.object().shape({
    firstName: yup.string().trim().required('First Name is required.').matches(/.{2,}/, {
      excludeEmptyString: true,
      message: 'Use at least 2 characters.',
    }),
    lastName: yup.string().trim().required('Last Name is required.').matches(/.{2,}/, {
      excludeEmptyString: true,
      message: 'Use at least 2 characters.',
    }),
    email: yup.string().trim().required('Email is required.').email('Invalid Email.'),
    streetAddress:
      activeBrand?.key === Brand.GuestBookingsAMZ
        ? yup.string().trim()
        : yup.string().trim().required('Street Address is required.'),
    aptNumber: yup.string().trim(),
    city:
      activeBrand?.key === Brand.GuestBookingsAMZ
        ? yup.string().trim()
        : yup.string().trim().required('City is required.'),
    country: yup.string().trim().required('Country is required.'),
    state:
      activeBrand?.key === Brand.GuestBookingsAMZ
        ? yup.string().trim()
        : yup.string().trim().required('State is required.'),
    zipCode:
      activeBrand?.key === Brand.GuestBookingsAMZ
        ? yup.string().trim()
        : yup
            .string()
            .trim()
            .when('countryCode', {
              is: 'CA',
              then: yup.string().matches(/^[a-zA-Z]\d[a-zA-Z](\s?\d[a-zA-Z]\d)?$/, 'Zip Code is invalid'),
              otherwise: yup
                .string()
                .trim()
                .when('countryCode', {
                  is: 'US',
                  then: yup.string().matches(/^\d{5}(?:-\d{4})?$/, 'Zip Code is invalid'),
                  otherwise: yup.string().trim().required('Zip code is required'),
                }),
            }),
  });

  const {
    handleSubmit: handleFormSubmit,
    formState: { errors },
    control,
    trigger,
    setValue,
    watch,
  } = useForm<BillingDetails>({
    mode: 'onBlur',
    defaultValues: initialDetails || {},
    resolver: yupResolver(validationSchema),
  });

  // #region handling Google Place Autocomplete
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddress | null | undefined>(null);

  useEffect(() => {
    if (selectedAddress) {
      setValue('streetAddress', selectedAddress.street);
      setValue('city', selectedAddress.city);
      setValue('country', selectedAddress.countryCode);
      setValue('zipCode', selectedAddress.zipCode);

      if (selectedAddress.countryCode === 'US' || selectedAddress.countryCode === 'CA') {
        setValue('state', selectedAddress.stateCode);
      } else {
        setValue('state', selectedAddress.state);
      }

      // trigger validation for all the set fields to avoid showing redundant validation messages
      trigger('streetAddress');
      trigger('city');
      trigger('country');
      trigger('zipCode');
      trigger('state');
    }
  }, [selectedAddress, setValue, trigger]);
  // #endregion handling Google Place Autocomplete

  useEffect(() => {
    if (validateOnOpen) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateOnOpen]);

  const handleBillingSubmit = (data: BillingDetails) => {
    let billingDetailsData = { ...data };

    billingDetailsData = { ...billingDetailsData, stateCode: data.state };

    onBillingDataChanged(billingDetailsData);
    return true;
  };

  const handleCountryChange = (code: string | undefined) => {
    setValue('state', '');

    // trigger validation for state field
    trigger('state');
  };

  const selectedCity = watch('city');
  const selectedZipCode = watch('zipCode');
  const selectedState = watch('state');
  const selectedCountryCode = watch('country');
  const isSelectedCountryStateCodeRequired = selectedCountryCode === 'US' || selectedCountryCode === 'CA';

  return (
    <Box sx={{ mt: 3 }}>
      <form onSubmit={handleFormSubmit(handleBillingSubmit)} autoComplete="off" ref={formRef}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              name="firstName"
              control={control as unknown as Control<FieldValues, object>}
              fullWidth
              isAutoFocused
              label="First Name"
              placeholder="First Name"
              error={errors.firstName?.message != null}
              helperText={errors.firstName?.message}
              testId={`${testId}FirstName`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="lastName"
              fullWidth
              label="Last Name"
              placeholder="Last Name"
              error={errors.lastName?.message != null}
              helperText={errors.lastName?.message}
              testId={`${testId}LastName`}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="email"
              fullWidth
              label="Email"
              placeholder="Email"
              error={errors.email?.message != null}
              helperText={errors.email?.message}
              testId={`${testId}Email`}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <AddressAutocomplete
              name="streetAddress"
              label="Street Address"
              placeholder="Street Address"
              error={errors?.streetAddress?.message != null}
              helperText={errors?.streetAddress?.message}
              testId={`${testId}StreetAddress`}
              setSelectedAddress={setSelectedAddress}
              control={control as unknown as Control<FieldValues, object>}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="aptNumber"
              fullWidth
              label="Apt or suite number"
              placeholder="Apt or suite number"
              error={errors.aptNumber?.message != null}
              helperText={errors.aptNumber?.message}
              testId={`${testId}AptNumber`}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="city"
              fullWidth
              label="City"
              placeholder="City"
              InputLabelProps={{ shrink: !!selectedCity }}
              error={errors.city?.message != null}
              helperText={errors.city?.message}
              testId={`${testId}City`}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledAutocomplete
              name="country"
              label="Country"
              placeholder="Country"
              options={countryList || []}
              error={errors.country?.message != null}
              helperText={errors.country?.message}
              testId={`${testId}Country`}
              control={control as unknown as Control<FieldValues, object>}
              requiredMessage="Country is required"
              onChange={handleCountryChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {isSelectedCountryStateCodeRequired && (
              <ControlledAutocomplete
                name="state"
                label="State"
                placeholder="State"
                options={selectedCountryCode === 'US' ? usStatesList : selectedCountryCode === 'CA' ? caStatesList : []}
                error={errors.state?.message != null}
                helperText={errors.state?.message}
                testId={`${testId}State`}
                control={control as unknown as Control<FieldValues, object>}
                onChange={undefined}
              />
            )}
            {!isSelectedCountryStateCodeRequired && (
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="state"
                fullWidth
                InputLabelProps={{ shrink: !!selectedState }}
                label="State/Region"
                placeholder="State/Region"
                error={errors.state?.message != null}
                helperText={errors.state?.message}
                testId={`${testId}Region`}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="zipCode"
              fullWidth
              label="Zip Code"
              placeholder="Zip Code"
              InputLabelProps={{ shrink: !!selectedZipCode }}
              error={errors.zipCode?.message != null}
              helperText={errors.zipCode?.message}
              testId={`${testId}ZipCode`}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export const BillingFormSkeleton = () => {
  const fh = 50;
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={fh} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={fh} />
      </Grid>
      {/* row2 */}
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={fh} />
      </Grid>
      {/* row3 */}
      <Grid item xs={9}>
        <Skeleton variant="rectangular" height={fh} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height={fh} />
      </Grid>
      {/* row4 */}
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={fh} />
      </Grid>
      {/* row5 */}
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={fh} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={fh} />
      </Grid>
      {/* row6 */}
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={fh} />
      </Grid>
      <Grid item xs={12} justifyContent="flex-end" container>
        <Grid item>
          <Skeleton variant="rectangular" height={fh} width={120} />
        </Grid>
      </Grid>
    </Grid>
  );
};
