export const AGE_BAND_TYPE = {
  Adult: 'Adult',
  Senior: 'Senior',
  Youth: 'Youth',
  Child: 'Child',
  Infant: 'Infant',
  Traveler: 'Traveler',
  InfantHeld: 'Infant Held',
  Student: 'Student',
};

export const AIR_TRIP_TYPE = {
  OneWay: 'One-Way',
  RoundTrip: 'RoundTrip',
  MultiCity: 'MultiCity',
};
