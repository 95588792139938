import React, { useMemo } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { ContentType } from '@one/api-models/lib/Admin/Sales/ContentType';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';
import { CabinCategorySummary } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/CabinCategorySummary';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/CruiseOrderItemSummary';
import { ItineraryDay } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/ItineraryDay';
import { Traveler } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/Traveler';
import { InformationItem } from '@one/api-models/lib/Admin/Sales/Travel/InformationItem';
import { InformationSection } from '@one/api-models/lib/Admin/Sales/Travel/InformationSection';

import { DataDisplay } from 'common';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';
import { Info } from 'components/views/customers/components/profile/Assets/OrderDetails/Info';
import { OrderPricingSummary } from 'components/views/customers/components/profile/Assets/OrderDetails/OrderPricingSummary';

interface Props {
  orderItem: CruiseOrderItemSummary;
  purchaseSummary?: PurchaseSummary;
}

export const CruiseOrderItemDetails = ({ orderItem, purchaseSummary }: Props) => {
  const { productSummary } = orderItem;
  const { formatDate: _formatDate } = useFormat();
  const formatDate = (date: string | Date | null | undefined, format = 'dd MMM yyyy'): string => {
    if (!date) return 'N/A';
    return _formatDate(new Date(date!), true, format) ?? 'N/A';
  };
  return (
    <>
      <SectionTitle title="Overview" variant="h6" />
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2, lg: 4 }}>
        <DataDisplay
          label="Departure"
          value={
            <>
              {productSummary.departurePort.name}
              <Typography component="span" sx={{ display: 'block' }}>
                {formatDate(productSummary.departureDate, 'ccc, dd MMM yyyy')}
              </Typography>
            </>
          }
        />
        <Divider orientation="vertical" flexItem />
        <DataDisplay
          label="Arrival"
          value={
            <>
              {productSummary.arrivalPort.name}
              <Typography component="span" sx={{ display: 'block' }}>
                {formatDate(productSummary.arrivalDate, 'ccc, dd MMM yyyy')}
              </Typography>
            </>
          }
        />
        <Divider orientation="vertical" flexItem />

        <DataDisplay
          label="Duration"
          value={
            <Box display="flex" flexDirection="column">
              {`${productSummary.durationDays} nights`}
              <Itinerary orderItem={orderItem} />
            </Box>
          }
        />

        <Divider orientation="vertical" flexItem />
        <DataDisplay label="Cruise line" value={productSummary.cruiseLine.name} />
        <Divider orientation="vertical" flexItem />
        <DataDisplay label="Ship" value={productSummary.ship.name} />
      </Box>
      <SectionTitle title="Cabin details" variant="h6" sx={{ mt: 2 }} />
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2, lg: 4 }}>
        <DataDisplay label="Type" value={productSummary.cabinCategory.type} />
        <Divider orientation="vertical" flexItem />
        <DataDisplay label="Category" value={<CabinCategory data={productSummary.cabinCategory} />} />
        <Divider orientation="vertical" flexItem />
        <DataDisplay label="Number" value={`#${productSummary.cabinNumber}`} />
        <Divider orientation="vertical" flexItem />
        <DataDisplay
          label="Rate"
          value={
            <>
              {productSummary.rate.name}
              <Typography component="span" sx={{ display: 'block' }}>
                ({productSummary.rate.supplierReference})
              </Typography>
            </>
          }
        />
        <Divider orientation="vertical" flexItem />
        <DataDisplay label="Dining option" value={productSummary.diningPreference} />
      </Box>

      <SectionTitle title="Guests" variant="h6" sx={{ mt: 2 }} />
      <Box display="flex" flexDirection="row" gap={{ xs: 1, lg: 4 }}>
        <DataDisplay label="Adults" value={orderItem.numberOfAdults} />
        <Divider orientation="vertical" flexItem />
        <DataDisplay label="Children" value={orderItem.numberOfChildren} />
      </Box>

      <Divider sx={{ my: 2 }} />
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2, lg: 10 }}>
        {orderItem.travelers.map((traveler: Traveler, index: number) => (
          <Box key={`traveler_${index}`}>
            <SectionTitle title={`Traveler ${index + 1}`} variant="h6" />
            <Box display="flex" flexDirection="column" gap={1}>
              <DataDisplay
                label="Name"
                value={`${traveler.title ? `${traveler.title} ` : ''}${traveler.firstName} ${traveler.lastName}`}
              />
              {traveler.citizenshipCountryCode && (
                <DataDisplay label="Citizenship" value={traveler.citizenshipCountryCode} />
              )}
              {traveler.dateOfBirth && <DataDisplay label="Date of birth" value={formatDate(traveler.dateOfBirth)} />}
              {traveler.email && <DataDisplay label="Email" value={traveler.email} />}
              {traveler.phone && <DataDisplay label="Phone" value={traveler.phone} />}
              {traveler.pastPassengerNumber && <DataDisplay label="Loyalty no" value={traveler.pastPassengerNumber} />}
            </Box>
          </Box>
        ))}
      </Box>
      {purchaseSummary && (
        <Box sx={{ my: 4 }}>
          <OrderPricingSummary purchaseSummary={purchaseSummary} />
        </Box>
      )}
    </>
  );
};

const CabinCategory = (props: { data: CabinCategorySummary }) => {
  const { data } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleOpenDetails = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDetails = (): void => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);
  const cabinCategoryAmenities: string[] = useMemo(() => {
    let desc: string[] = [];
    const contents = data.contents.find(
      (c: InformationSection) => c.type === ContentType.CabinCategoryAmenities,
    )?.items;

    if (contents != null) {
      contents.forEach((description: InformationItem) => {
        try {
          const items = JSON.parse(description.value);
          desc = desc.concat(items);
        } catch (e) {
          desc.push(description.value);
        }
      });
    }
    return desc;
  }, [data.contents]);

  const hasMoreDetails = useMemo(
    () =>
      data.bedConfiguration != null ||
      cabinCategoryAmenities.length > 0 ||
      data.deck != null ||
      data.location != null ||
      data.occupancyDetails != null,
    [data, cabinCategoryAmenities],
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} component="span">
      {data.name}
      {hasMoreDetails && (
        <>
          <IconButton sx={{ ml: 1 }} size="small" aria-describedby="cabin-details" onClick={handleOpenDetails}>
            <InfoOutlined />
          </IconButton>

          <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleCloseDetails}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography sx={{ p: 2 }}>
              {data.deck && <Info label="Deck" value={data.deck} />}
              {data.location && <Info label="Location" value={data.location} />}
              {data.occupancyDetails && (
                <Info label="Occupancy" value={`Up to ${data.occupancyDetails.maxAllowedTotal} guests`} />
              )}
              {data.bedConfiguration && <Info label="Bed" value={data.bedConfiguration} />}
              {cabinCategoryAmenities.length > 0 && (
                <Info
                  label="Amenities"
                  value={
                    <ul style={{ margin: 0 }}>
                      {cabinCategoryAmenities.map((s: string, i: number) => (
                        <li key={`description-${i}`}>
                          <Typography variant="body2" component={Box} sx={{ whiteSpace: 'pre-line' }}>
                            {s}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  }
                />
              )}
            </Typography>
          </Popover>
        </>
      )}
    </Box>
  );
};

const Itinerary = ({ orderItem }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleOpenDetails = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDetails = (): void => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);
  return (
    <>
      <Button size="small" aria-describedby="cabin-details" onClick={handleOpenDetails}>
        See itinerary
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleCloseDetails}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 1 }}>
          <List
            sx={{
              li: {
                pb: 0,
                pt: 0,
              },
            }}
          >
            {orderItem.productSummary.itinerary.days.map((day: ItineraryDay) => (
              <ListItem alignItems="flex-start" key={day.dayIndex}>
                <ListItemText
                  primary={`Day ${day.dayIndex + 1}`}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                  secondary={
                    <>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>
                        {day.title}
                      </Typography>
                      <Grid container>
                        {day.arrivalTime && (
                          <Grid item>
                            <Typography variant="caption">Arrival</Typography>
                            <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                              {day.arrivalTime}
                            </Typography>
                          </Grid>
                        )}
                        {day.departureTime && (
                          <Grid item component={Box} pl={day.arrivalTime ? 2 : 0}>
                            <Typography variant="caption">Departure</Typography>
                            <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                              {day.departureTime}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {day.highlights && (
                        <Typography variant="body1" component={Box} mt={1}>
                          {day.highlights}
                        </Typography>
                      )}
                    </>
                  }
                  secondaryTypographyProps={{
                    style: { opacity: 1 },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Typography>
      </Popover>
    </>
  );
};
