import { FC, useEffect, useState } from 'react';
import { get } from 'lodash';

import { Backdrop, Box, CircularProgress, Grid, Skeleton, Typography } from '@mui/material';
import { CybersourceGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/CybersourceGatewayConfiguration';
import { Shift4GatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/Shift4GatewayConfiguration';
import { StripeGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/StripeGatewayConfiguration';

import {
  AddPaymentMethodStripeComponent,
  AddPaymentMethodStripeComponentProps,
} from 'components/_common/paymentPlan/AddPaymentMethodStripeComponent';
import { PaymentSetupType, usePaymentSetup } from 'components/hooks/usePaymentSetup';
import { PaymentProviderType } from 'models/PaymentProvider';

import { StripeWrapper } from '../views/paymentPlans/components/StripeWrapper';
import { PaymentDetailsCybersource } from '../views/paymentPlans/PaymentDetailsCybersource';
import { PaymentDetailsShift4 } from '../views/paymentPlans/PaymentDetailsShift4';
import { PaymentDetailsStripe } from '../views/paymentPlans/PaymentDetailsStripe';

import { AddPaymentMethodCybersourceComponent } from './paymentPlan/AddPaymentMethodCybersourceComponent';

interface PaymentDetailsStripeWrappedProps {
  paymentGatewayConfiguration: StripeGatewayConfiguration;
}

const PaymentDetailsStripeWrapped: FC<PaymentDetailsStripeWrappedProps> = ({ paymentGatewayConfiguration }) => (
  <StripeWrapper paymentGatewayConfiguration={paymentGatewayConfiguration}>
    <PaymentDetailsStripe />
  </StripeWrapper>
);

interface AddPaymentMethodStripeComponentWrappedProps extends AddPaymentMethodStripeComponentProps {
  paymentGatewayConfiguration: StripeGatewayConfiguration;
}

const PaymentPlanStripePaymentsSectionWrapped: React.FC<AddPaymentMethodStripeComponentWrappedProps> = ({
  paymentGatewayConfiguration,
  ...props
}) => (
  <StripeWrapper paymentGatewayConfiguration={paymentGatewayConfiguration}>
    <AddPaymentMethodStripeComponent {...props} paymentGatewayConfiguration={paymentGatewayConfiguration} />
  </StripeWrapper>
);

type PaymentComponentType = React.FC<any>;

type PaymentComponentMap = {
  [key: string]: PaymentComponentType;
};

interface PaymentProviderProps {
  type: PaymentSetupType;
  // Unknown props for conditionally rendered component
  [x: string]: any;
}

export const PaymentProvider: React.FC<PaymentProviderProps> = ({ type, ...props }) => {
  const [paymentGatewayConfiguration, setPaymentGatewayConfiguration] = useState<
    StripeGatewayConfiguration | Shift4GatewayConfiguration | CybersourceGatewayConfiguration
  >();
  const [isLoadingConfiguration, setIsLoadingConfiguration] = useState(false);
  const paymentType = get(paymentGatewayConfiguration, '$type');

  const paymentSetupMutation = usePaymentSetup(type, setIsLoadingConfiguration, setPaymentGatewayConfiguration);

  useEffect(() => {
    setIsLoadingConfiguration(true);
    paymentSetupMutation.mutateAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchConfiguration = () => {
    setIsLoadingConfiguration(true);
    paymentSetupMutation.mutateAsync();
  };

  const programSaleComponentMap: PaymentComponentMap = {
    [PaymentProviderType.Stripe]: PaymentDetailsStripeWrapped,
    [PaymentProviderType.Shift4]: PaymentDetailsShift4,
    [PaymentProviderType.Cybersource]: PaymentDetailsCybersource,
  };

  const paymentPlansComponentMap: PaymentComponentMap = {
    [PaymentProviderType.Stripe]: PaymentPlanStripePaymentsSectionWrapped,
    [PaymentProviderType.Cybersource]: AddPaymentMethodCybersourceComponent,
  };

  const componentMap = {
    programSale: programSaleComponentMap,
    paymentPlans: paymentPlansComponentMap,
  };

  // Default payment provider Stripe
  const PaymentComponent =
    paymentType && componentMap[type]
      ? componentMap[type][paymentType] || componentMap[type][PaymentProviderType.Stripe]
      : componentMap[type][PaymentProviderType.Stripe];

  return paymentGatewayConfiguration ? (
    <PaymentComponent
      {...props}
      paymentGatewayConfiguration={paymentGatewayConfiguration}
      isLoadingConfiguration={isLoadingConfiguration}
      refetchConfiguration={refetchConfiguration}
    />
  ) : (
    <PaymentSkeleton isLoadingConfiguration={isLoadingConfiguration} />
  );
};

const PaymentSkeleton = ({ isLoadingConfiguration }: { isLoadingConfiguration: boolean }) => {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Skeleton variant="rectangular" height={180} sx={{ mt: 1 }} />
      </Box>

      <Backdrop
        open={isLoadingConfiguration}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
      >
        <Grid spacing={1} container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress color="inherit" data-testid="LoadingSpinner" />
          </Grid>
          <Grid item>
            <Typography variant="h6">Loading...</Typography>
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
};
