import { Colors } from './Colors';

export const StatusColors = {
  // Common Status
  Failed: { background: Colors.softRed, label: Colors.vividRed },
  Cancelled: { background: Colors.softRed, label: Colors.vividRed },
  PartiallyPaid: { background: Colors.brightYellow, label: Colors.mutedBrown },
  PartiallyRefunded: { background: Colors.brightOrange, label: Colors.white },

  // Order Status
  Unknown: { background: Colors.lightGray, label: Colors.darkGrayBlue },
  Draft: { background: Colors.softBlue, label: Colors.vividBlue },
  OrderCreated: { background: Colors.brightYellow, label: Colors.mutedBrown },
  OrderConfirmed: { background: Colors.paleYellow, label: Colors.mutedBrown },
  OrderCompleted: { background: Colors.lightGreen, label: Colors.darkGreen },
  OrderCancelled: { background: Colors.lightGray, label: Colors.darkGrayBlue },
  OrderRefunded: { background: Colors.softRed, label: Colors.vividRed },
  OrderArchived: { background: Colors.lightGray, label: Colors.darkGrayBlue },
  CancellationRequested: { background: Colors.brightOrange, label: Colors.white },
  CancellationInProgress: { background: Colors.brightOrange, label: Colors.white },

  // Fulfillment Status
  FulfillmentInitiated: { background: Colors.paleYellow, label: Colors.mutedBrown },
  FulfillmentCompleted: { background: Colors.lightGreen, label: Colors.darkGreen },

  // Payment Status
  Unpaid: { background: Colors.softRed, label: Colors.vividRed },
  FullyPaid: { background: Colors.lightGreen, label: Colors.darkGreen },
  FullyRefunded: { background: Colors.softRed, label: Colors.vividRed },
  AwaitingPayment: { background: Colors.brightYellow, label: Colors.mutedBrown },
  PendingPaymentAuthorization: { background: Colors.brightYellow, label: Colors.mutedBrown },

  //TransactionStatus
  Deleted: { background: Colors.lightGray, label: Colors.gray },
  New: { background: Colors.lightBlue, label: Colors.vividBlue },
  Succeeded: { background: Colors.softGreen, label: Colors.darkGreen },
  Rejected: { background: Colors.softRed, label: Colors.vividRed },
  Pending: { background: Colors.paleYellow, label: Colors.vividOrange },
  PendingCapture: { background: Colors.paleOrange, label: Colors.vividOrange },
  Expired: { background: Colors.lightGray, label: Colors.mediumGray },
  PendingRefund: { background: Colors.softOrange, label: Colors.vividOrange },
  Refunded: { background: Colors.lightTeal, label: Colors.darkTeal },
  Blocked: { background: Colors.palePurple, label: Colors.darkPurple },
  Disputed: { background: Colors.palePink, label: Colors.vividPink },
  EarlyFraudWarning: { background: Colors.softPeach, label: Colors.darkPeach },
  Incomplete: { background: Colors.paleYellow, label: Colors.vividYellow },
  Uncaptured: { background: Colors.paleLavender, label: Colors.darkLavender },
  WaitingOnFunding: { background: Colors.paleOrange, label: Colors.vividOrange },
};
