import { CybersourceGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/CybersourceGatewayConfiguration';
import { Shift4GatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/Shift4GatewayConfiguration';
import { StripeGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/StripeGatewayConfiguration';

export const PaymentProviderType = {
  Stripe: StripeGatewayConfiguration.$type,
  Shift4: Shift4GatewayConfiguration.$type,
  Cybersource: CybersourceGatewayConfiguration.$type,
};

export const PaymentProviderList = ['Stripe', 'Shift4', 'Cybersource'];
